const categoricalWages = {
    "very low earnings": 3,
    "low earnings": 6,
    "low-medium earnings": 8,
    "medium earnings": 10,
    "medium-high earnings": 12,
    "high earnings": 20,
    "very high earnings": 30

}

export { categoricalWages }