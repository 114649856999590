const prices = {
    "Classification": 20,
    "Point Annotation": 3,
    "Line Annotation": 15,
    "2D Box Annotation": 15,
    "3D Box Annotation": 20,
    "Polygon Annotation": 50,
    "Lidar 3D Box Annotation": 60,
    "Lidar Pixelwise Annotation": 200,
}

var classPrices = {}

for (var i = 2; i < 1000; i++) {
    classPrices[i] = 3 + i * 0.1
}

var accuracyPrices = {}

for (var a = 80; a < 100; a++) {
    accuracyPrices[a] = (1 + ((a - 80) ** 1.2 * 0.01)).toFixed(3)
}

function calculateImageDiscount(imageNumber) {
    var imageDiscountValue = Math.max((1 - (imageNumber * 0.000003)).toFixed(2), 0.5)
    return imageDiscountValue
}

export { prices, classPrices, accuracyPrices, calculateImageDiscount }