import React from 'react';
import './App.css';
import CalculatorView from './views/CalculatorView/calculatorView';
import { globalTheme } from './style/MaterialUiStyle';
import { ThemeProvider } from '@material-ui/core/styles';
import Footer from './layouts/Footer/Footer';
import ReactGA from 'react-ga';

const trackingId = "UA-182647481-2"

ReactGA.initialize(trackingId)

function App() {

  return (
    <div className="App">
      <ThemeProvider theme={globalTheme}>
        <CalculatorView />
        <Footer></Footer>
      </ThemeProvider>
    </div>
  );
}

export default App;
